import React from 'react'
import './styles.scss'

const Test3d = () => {
  return (
    <main className='test3d-page'>
        <iframe src='../../test2.html'></iframe>
    </main>
  )
}

export default Test3d